import React from "react"

import Layout from "../components/layout-static"
import Seo from "../components/seo"


const Page = () => {
  const metadata = {
    title: "Disclaimer",
    heroH1: "Disclaimer",
    heroH2: "When experience, integrity, and results matter.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>DISCLAIMER</h2>
            <p className="mb-8">
              No information that you obtain from this web site is legal advice,
              nor is it intended to be.
            </p>
            <p className="mb-8">
              You should consult an attorney for individualized advice regarding
              your own unique situation.
            </p>
            <p className="mb-8">
              No attorney-client relationship is formed between Bartlett Legal
              Group, LLC and you by viewing this web site.
            </p>
            <p className="mb-8">
              Past results may not be indicative of the outcome of your case.
              All cases are valued on their own merits.
            </p>
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default Page
